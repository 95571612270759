<template>
  <div class="box">
    <div class="flex j_c_c a_i_s">
      <div class="flex a_i_s left_box">
        <div>
          <svg
            width="209"
            height="37"
            viewBox="0 0 269 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1379_36745)">
              <path
                d="M29.7 0H24.5V36H31.9V2.2C31.9 1 30.9 0 29.7 0Z"
                fill="white"
              />
              <path d="M5.2 0H0V36H7.4V2.2C7.4 1 6.4 0 5.2 0Z" fill="white" />
              <path
                d="M28.9677 21.7438V14.3438L3.06765 14.3437V21.7438L28.9677 21.7438Z"
                fill="white"
              />
              <path
                d="M47.3 0H36.4L49.5 13.1C51 14.6 53.3 14.6 54.8 13.1L57.6 10.3L47.3 0Z"
                fill="white"
              />
              <path
                d="M36.7001 35.9996H47.6001L57.6001 25.8996L54.8001 23.0996C53.3001 21.5996 51.0001 21.5996 49.5001 23.0996L36.7001 35.9996Z"
                fill="white"
              />
              <path
                d="M83.4 0H72.5L59.9 12.7C56.9 15.7 56.9 20.6 59.9 23.6L72.3 36H83.2L66.6 19.3C65.9 18.6 65.9 17.6 66.6 16.9L83.4 0Z"
                fill="white"
              />
              <path d="M94.1 11.9004H86.7V36.1004H94.1V11.9004Z" fill="white" />
              <path
                d="M134.7 0.0996094H129.5V36.0996H136.9V2.29961C136.9 1.09961 135.9 0.0996094 134.7 0.0996094Z"
                fill="white"
              />
              <path
                d="M97.6 11.9004V36.1004H105V19.3004H111.3C115.3 19.3004 118.6 22.5004 118.6 26.6004V36.2004H126V24.2004C126 17.4004 120.5 11.9004 113.7 11.9004H97.6Z"
                fill="white"
              />
              <path
                d="M91.9 0.0996094H86.7V7.49961H94.1V2.29961C94.1 1.09961 93.1 0.0996094 91.9 0.0996094Z"
                fill="white"
              />
              <path
                d="M157.9 11.9004H148.4L139.7 20.6004C137.8 22.5004 137.8 25.6004 139.7 27.6004L148.2 36.1004H157.7L146.7 25.0004C146.2 24.5004 146.2 23.7004 146.7 23.2004L157.9 11.9004Z"
                fill="white"
              />
              <path
                d="M167.9 3.59961H165.7V12.9996H167.9V3.59961Z"
                fill="white"
              />
              <path
                d="M186.2 12.3996H177.6C176.1 12.3996 174.8 11.1996 174.8 9.59961V0.0996094H177V9.59961C177 9.89961 177.2 10.0996 177.5 10.0996H186.1V12.3996H186.2Z"
                fill="white"
              />
              <path
                d="M169.8 7.49961L169.4 5.29961L183.5 2.39961C183.6 2.39961 183.8 2.19961 183.8 2.09961V0.0996094H186V2.09961C186 3.29961 185.1 4.39961 184 4.59961L169.8 7.49961Z"
                fill="white"
              />
              <path
                d="M186.2 14.9004H163.4V17.1004H186.2V14.9004Z"
                fill="white"
              />
              <path
                d="M175.9 12.5996H173.7V21.4996H175.9V12.5996Z"
                fill="white"
              />
              <path
                d="M167.2 0.0996094C166.8 2.19961 165.4 3.89961 163.5 4.69961V7.09961C166.6 6.09961 169 3.39961 169.5 0.0996094H167.2Z"
                fill="white"
              />
              <path
                d="M240.7 0.0996094H218.1V2.29961H240.7V0.0996094Z"
                fill="white"
              />
              <path
                d="M238.1 15.1004H222.9C221.9 15.1004 221 14.7004 220.3 14.0004C219.6 13.3004 219.3 12.3004 219.4 11.3004L220.2 1.40039L222.4 1.60039L221.6 11.5004C221.6 11.9004 221.7 12.2004 221.9 12.5004C222.1 12.8004 222.5 12.9004 222.8 12.9004H238V15.1004H238.1Z"
                fill="white"
              />
              <path
                d="M240.7 19.2988H218.1V21.4988H240.7V19.2988Z"
                fill="white"
              />
              <path
                d="M238.6 20.2996L236.4 20.0996L237.2 10.1996C237.2 9.79961 237.1 9.49961 236.9 9.19961C236.7 8.89961 236.3 8.79961 236 8.79961H220.8V6.59961H236C237 6.59961 237.9 6.99961 238.6 7.69961C239.3 8.39961 239.6 9.39961 239.5 10.2996L238.6 20.2996Z"
                fill="white"
              />
              <path
                d="M259.8 0.0996094H257.6V3.19961H259.8V0.0996094Z"
                fill="white"
              />
              <path
                d="M266 0.0996094H263.8V3.19961H266V0.0996094Z"
                fill="white"
              />
              <path
                d="M255.7 0.0996094H245V2.29961H255.7V0.0996094Z"
                fill="white"
              />
              <path d="M248.1 1.5H245.9V17.4H248.1V1.5Z" fill="white" />
              <path d="M254.5 1.5H252.3V21.5H254.5V1.5Z" fill="white" />
              <path d="M253.3 6H247V8.2H253.3V6Z" fill="white" />
              <path
                d="M253.3 11.4004H247V13.6004H253.3V11.4004Z"
                fill="white"
              />
              <path
                d="M255.7 16.6992H245V18.8992H255.7V16.6992Z"
                fill="white"
              />
              <path d="M267.9 3H255.7V5.2H267.9V3Z" fill="white" />
              <path
                d="M267.9 9.69922H255.7V11.8992H267.9V9.69922Z"
                fill="white"
              />
              <path
                d="M260.7 4.29883V10.3988C260.7 13.6988 258.8 18.1988 255.7 19.1988V21.4988C260 20.5988 262.9 15.1988 262.9 10.3988V4.29883H260.7Z"
                fill="white"
              />
              <path
                d="M262.9 4.29883V10.3988C262.9 13.6988 264.8 18.1988 267.9 19.1988V21.4988C263.6 20.5988 260.7 15.1988 260.7 10.3988V4.29883H262.9Z"
                fill="white"
              />
              <path d="M195.3 4H193.1V21.5H195.3V4Z" fill="white" />
              <path d="M213.8 1.5H197.3V3.7H213.8V1.5Z" fill="white" />
              <path d="M213.4 5.5H197.7V7.7H213.4V5.5Z" fill="white" />
              <path d="M213.8 9.5H197.3V11.7H213.8V9.5Z" fill="white" />
              <path
                d="M211.3 21.4988H199.7C198.5 21.4988 197.5 20.4988 197.5 19.2988V15.4988C197.5 14.2988 198.5 13.2988 199.7 13.2988H211.3C212.5 13.2988 213.5 14.2988 213.5 15.4988V19.2988C213.5 20.4988 212.5 21.4988 211.3 21.4988ZM211.2 15.4988H199.6V19.2988H211.1V15.4988H211.2Z"
                fill="white"
              />
              <path
                d="M206.5 0.0996094H204.3V2.09961H206.5V0.0996094Z"
                fill="white"
              />
              <path
                d="M196.9 0.0996094H194.6C193.8 2.99961 192.3 5.69961 190.5 7.89961V11.1996C193.5 8.19961 195.8 4.39961 196.9 0.0996094Z"
                fill="white"
              />
              <path
                d="M166.9 33.6996C166.9 33.7996 167 33.9996 167.1 34.0996C165.3 35.3996 165.1 35.5996 164.9 35.7996C164.8 35.6996 164.7 35.4996 164.6 35.4996C164.8 35.3996 165.1 34.9996 165.1 34.5996V30.7996H163.4V30.2996H165.5V34.6996L166.9 33.6996ZM165.9 29.0996C165.6 28.6996 164.9 27.9996 164.4 27.5996L164.7 27.2996C165.2 27.7996 165.9 28.3996 166.2 28.7996L165.9 29.0996ZM172.4 35.2996V35.7996H166.5V35.2996H169V27.0996H169.5V30.3996H171.7V30.8996H169.5V35.2996H172.4Z"
                fill="white"
              />
              <path
                d="M184.3 35.2V35.7H175.5V35.2H179.7V32.4H176.6V31.9H179.7V29.5H177.2C176.9 30.2 176.5 30.8 176.1 31.2C176 31.1 175.8 31 175.7 30.9C176.5 30 177.2 28.6 177.6 27.2L178.1 27.3C177.9 27.9 177.7 28.5 177.5 29H179.8V27H180.3V29H184V29.5H180.3V31.9H183.7V32.4H180.3V35.2H184.3Z"
                fill="white"
              />
              <path
                d="M192.3 27.2C192.3 27.3 192.2 27.3 192.1 27.4C193 28.5 194.8 29.6 196.4 30.1C196.3 30.2 196.1 30.4 196 30.6C194.5 30.1 192.8 29 191.8 27.8C190.8 29 189.1 30.1 187.5 30.7C187.4 30.6 187.3 30.4 187.2 30.2C189 29.7 190.9 28.3 191.8 27L192.3 27.2ZM191.2 34.4H188.8V35.3H188.3V31.1H191.2V34.4ZM188.8 31.5V33.9H190.8V31.5H188.8ZM193.8 30.1H190V29.7H193.8V30.1ZM195.6 33.9C195.6 34.2 195.5 34.4 195.3 34.5C195 34.6 194.6 34.6 193.8 34.6C193.8 34.5 193.7 34.3 193.6 34.1C194.3 34.1 194.8 34.1 194.9 34.1C195.1 34.1 195.1 34 195.1 33.9V31.5H192.9V36H192.4V31H195.6V33.9Z"
                fill="white"
              />
              <path
                d="M202 27.2C201.8 27.9 201.4 28.6 201.1 29.3V36.1H200.6V30.1C200.3 30.7 199.9 31.2 199.5 31.6C199.5 31.5 199.3 31.3 199.2 31.2C200.1 30.2 201 28.6 201.5 27.1L202 27.2ZM208.3 28.7V29.1H202V28.7H208.3ZM202.6 32.9H207.5V36H207V35.6H202.9V36H202.5V32.9H202.6ZM207.5 30.5H202.8V30.1H207.5V30.5ZM202.7 31.5H207.4V31.9H202.7V31.5ZM203.1 33.3V35.2H207.2V33.3H203.1ZM205 28.5C204.9 28.1 204.6 27.6 204.3 27.2L204.7 27C205 27.4 205.3 27.9 205.4 28.3L205 28.5Z"
                fill="white"
              />
              <path
                d="M211.5 35.4004C211.9 34.9004 212.3 34.0004 212.6 33.4004L213 33.6004C212.7 34.2004 212.4 35.1004 212 35.6004L211.5 35.4004ZM218.9 32.6004H212.9V27.9004H215.2C215.4 27.6004 215.6 27.2004 215.7 26.9004L216.2 27.0004C216.1 27.3004 215.9 27.6004 215.7 27.9004H218.8V32.6004H218.9ZM218.4 28.4004H213.4V29.4004H218.4V28.4004ZM218.4 29.8004H213.4V30.8004H218.4V29.8004ZM218.4 31.2004H213.4V32.2004H218.4V31.2004ZM217.4 35.4004C218 35.4004 218.1 35.2004 218.1 34.2004C218.2 34.3004 218.4 34.4004 218.6 34.4004C218.5 35.6004 218.3 35.9004 217.5 35.9004H215C214 35.9004 213.7 35.8004 213.7 35.1004V33.3004H214.2V35.1004C214.2 35.4004 214.3 35.4004 215 35.4004H217.4ZM215.5 32.7004C216 33.2004 216.6 33.8004 216.9 34.2004L216.5 34.5004C216.2 34.1004 215.6 33.4004 215.1 32.9004L215.5 32.7004ZM219.1 33.2004C219.6 33.8004 220.1 34.6004 220.3 35.1004L219.9 35.3004C219.7 34.8004 219.2 34.0004 218.8 33.4004L219.1 33.2004Z"
                fill="white"
              />
              <path
                d="M227.5 31.7C227.2 31.4 226.3 30.8 225.8 30.5C225.5 31.1 224.9 31.7 223.7 32.2C223.6 32.1 223.5 31.9 223.4 31.8C224.8 31.2 225.3 30.5 225.5 29.8H223.4V29.4H225.5C225.5 29.2 225.5 29.1 225.5 29V28.3H224.5C224.3 28.7 224.1 29 223.8 29.3C223.7 29.3 223.5 29.1 223.4 29C223.9 28.5 224.3 27.7 224.5 27L225 27.1C224.9 27.4 224.8 27.6 224.7 27.9H227.6V28.3H226V29C226 29.1 226 29.3 226 29.4H227.9V29.8H226C226 29.9 225.9 30 225.9 30.1C226.3 30.3 227.5 31.1 227.8 31.3L227.5 31.7ZM225 32H230.9V36H230.4V35.6H225.5V36H225V32ZM225.5 32.4V33.6H230.4V32.4H225.5ZM230.4 35.2V34H225.5V35.2H230.4ZM231.7 27.9V31.2H228.4V27.9H231.7ZM231.3 28.3H228.9V30.7H231.3V28.3Z"
                fill="white"
              />
              <path
                d="M237.4 29.9H235.6V29.5H237.4V28.9H236V28.5H237.5V28H235.8V27.6H237.5V27H237.9V27.6H239.4V28H237.9V28.6H239.2V29H237.9V29.6H239.5V30H237.9V30.6H237.5V29.9H237.4ZM235.7 35.5C236.1 35.1 236.4 34.4 236.6 33.9L237 34.1C236.8 34.6 236.5 35.4 236.2 35.8L235.7 35.5ZM243.2 33.3H236.4V32.9H242.8V32.1H236.8V31.7H242.8V31H236.5V30.6H243.3V33.3H243.2ZM241.3 35.5C241.8 35.5 241.9 35.4 241.9 34.5C242 34.6 242.2 34.6 242.3 34.7C242.2 35.7 242.1 36 241.3 36H239C238 36 237.8 35.9 237.8 35.3V33.9H238.3V35.3C238.3 35.5 238.4 35.6 239.1 35.6H241.3V35.5ZM239.5 33.4C240 33.7 240.6 34.1 240.9 34.5L240.6 34.8C240.3 34.5 239.7 34 239.2 33.7L239.5 33.4ZM241.8 29.9H240V29.5H241.8V28.9H240.3V28.5H241.8V28H240.1V27.6H241.8V27H242.3V27.6H244V28H242.3V28.6H243.9V29H242.3V29.6H244.2V30H242.3V30.6H241.8V29.9ZM243.1 33.8C243.5 34.3 244 35.1 244.2 35.6L243.8 35.8C243.6 35.3 243.1 34.6 242.7 34L243.1 33.8Z"
                fill="white"
              />
              <path
                d="M250.6 33.9996V36.0996H250.2V34.0996C249.2 34.2996 248.2 34.3996 247.5 34.5996L247.4 34.0996L248 33.9996V27.8996H247.4V27.4996H251.1V27.8996H250.6V33.5996L251.4 33.4996V33.8996L250.6 33.9996ZM248.5 27.8996V29.4996H250.2V27.8996H248.5ZM248.5 31.5996H250.2V29.9996H248.5V31.5996ZM248.5 33.8996C249 33.7996 249.6 33.6996 250.2 33.5996V31.9996H248.5V33.8996ZM253.8 32.0996C254.3 33.6996 255.2 35.0996 256.5 35.6996C256.4 35.7996 256.2 35.9996 256.2 36.0996C255 35.4996 254.2 34.2996 253.6 32.8996C253.3 33.9996 252.7 35.1996 251.2 36.1996C251.1 36.0996 251 35.8996 250.8 35.8996C252.5 34.7996 253.1 33.3996 253.2 32.1996H251.1V31.7996H253.3C253.3 31.4996 253.3 31.1996 253.3 30.8996V29.6996H251.4V29.1996H253.9C254.3 28.6996 254.8 27.8996 255.1 27.2996L255.6 27.4996C255.3 28.0996 254.8 28.6996 254.5 29.1996H256V29.6996H253.8V30.8996C253.8 31.1996 253.8 31.4996 253.8 31.7996H256.4V32.1996H253.8V32.0996ZM252.8 28.8996C252.6 28.4996 252.2 27.7996 251.8 27.2996L252.2 27.0996C252.6 27.5996 253.1 28.1996 253.2 28.5996L252.8 28.8996Z"
                fill="white"
              />
              <path
                d="M262.9 32.3C262.9 32.4 262.9 32.6 262.9 32.7C260.1 33.2 259.8 33.3 259.6 33.4C259.6 33.3 259.5 33 259.4 32.9C259.6 32.9 259.8 32.6 260.2 32.3C260.4 32.1 260.9 31.5 261.4 30.8C259.9 31 259.7 31 259.5 31.1C259.5 31 259.4 30.7 259.3 30.6C259.4 30.6 259.6 30.3 259.8 30C260 29.7 260.8 28.4 261.2 27.2L261.7 27.4C261.2 28.5 260.6 29.6 260 30.5L261.6 30.4C261.8 30 262.1 29.7 262.3 29.3L262.7 29.6C262 30.8 261.1 31.9 260.3 32.8L262.9 32.3ZM259.3 34.9C260.2 34.7 261.5 34.5 262.8 34.2V34.6C261.6 34.9 260.3 35.2 259.4 35.4L259.3 34.9ZM265.9 28.9V30.7H268.1V31.2H263.2V30.7H265.4V28.9H263V28.4H265.4V27H265.9V28.4H268.3V28.9H265.9ZM263.5 32.3H267.8V36H267.3V35.6H264V36H263.5V32.3ZM264 32.8V35.2H267.4V32.8H264Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1379_36745">
                <rect width="268.2" height="36.1" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div>
          <div
            v-for="(itemMain, indexMain) in navs"
            :key="indexMain"
            class="navBoxItem"
          >
            <h2
              :class="{
                mainTabJump: itemMain.children.tab.length === 0,
                active_nav: itemMain.path.indexOf($route.name) != -1,
              }"
              @click="jump_nav_menu(itemMain)"
            >
              {{ itemMain.name }}
            </h2>
            <ul v-for="(item, index) in itemMain.children.tab" :key="index">
              <li class="active_type" v-show="item.name">{{ item.name }}</li>
              <li
                class="navTab"
                v-for="(item_, index_) in item.list"
                :key="index_"
                :class="{
                  active_choose: item_.path == $route.name,
                }"
                @click="jump_nav(item_)"
              >
                {{ item_.name }}
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div v-for="(itemMain, indexMain) in navsLong" :key="indexMain">
            <h2
              :class="{
                mainTabJump: itemMain.children.tab.length === 0,
                active_nav: itemMain.path.indexOf($route.name) != -1,
              }"
              @click="jump_nav_menu(itemMain)"
            >
              {{ itemMain.name }}
            </h2>
            <div class="flex">
              <ul v-for="(item, index) in itemMain.children.tab" :key="index" style="margin-right:50px">
                <li class="active_type">{{ item.name }}</li>
                <li
                  class="navTab"
                  v-for="(item_, index_) in item.list"
                  :key="index_"
                  :class="{
                    active_choose: item_.path == $route.name,
                  }"
                  @click="jump_nav(item_)"
                >
                  {{ item_.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center; line-height: 200%" class="right_box">
        <!-- <p style="font-size: 20px; font-weight: 800">15852938829</p> -->
        <p style="font-size: 16px; font-weight: 800; margin-bottom: 10px">
          南京市建邺区泰山路 159 号<br />
          正太中心大厦 B 座 1001 室
        </p>
        <div>
          <p>微信公众号</p>
          <img
            class="is_code"
            :src="require('@/assets/footer/code.png')"
            alt=""
          />
        </div>
      </div>
    </div>
    <p
      class="desc"
      style="font-size: 12px; color: #80838a; margin: 60px 0 0px 0"
    >
      COPYRIGHT © 2021 ROOTCLOUD. ALL RIGHTS RESERVED.
      江苏华信互联智能科技有限公司 版权所有 苏ICP备2021043087号-1
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navsLong: [
        {
          name: "产品与解决方案",
          path: [
            "HealthyManageCore",
            "SlowSickManageCore",
            "HosFollowUp",
            "RegionEdu",
            "healthyManageCore",
            "slowSickManageCore",
            "hosFollowUp",
            "regionEdu",
            "SmartChronicDisease",
            "SmartFollowup",
            "ChronicDisease",
            "SmartEducation",
            "IntelligentPhysical",
            "ChronicDisease",
            "IntelligentMonitoring",
          ],
          children: {
            tab: [
              {
                name: "解决方案",
                list: [
                  {
                    name: "健康管理中心",
                    path: "HealthyManageCore",
                  },
                  {
                    name: "慢病管理中心",
                    path: "SlowSickManageCore",
                  },
                  {
                    name: "院内、院外随访",
                    path: "HosFollowUp",
                  },
                  {
                    name: "区域健康宣教",
                    path: "RegionEdu",
                  },
                ],
              },
              {
                name: "产品与服务",
                list: [
                  {
                    name: "智慧慢病管理平台",
                    path: "SmartChronicDisease",
                  },
                  {
                    name: "智慧体检系统",
                    path: "IntelligentPhysical",
                  },
                  {
                    name: "智慧随访管理平台",
                    path: "SmartFollowup",
                  },
                  {
                    name: "全程满意度调查",
                    path: "SatisfactionSurvey",
                  },
                  // {
                  //   name: "慢病管理服务",
                  //   path: "ChronicDisease",
                  // },
                  {
                    name: "智慧宣教管理平台",
                    path: "SmartEducation",
                  },
                  {
                    name: "智能监测设备",
                    path: "IntelligentMonitoring",
                  },
                  {
                    name: "智慧家医工作站",
                    path: "SmartFamilyDoc",
                  },
                  {
                    name: "智慧慢病医患小程序",
                    path: "SmartWeapp",
                  },
                  {
                    name: "智慧决策驾驶舱",
                    path: "SmartDecision",
                  },
                  {
                    name: "智慧导检系统",
                    path: "SmartInspection",
                  },
                  {
                    name: "智慧医学知识库",
                    path: "SmartLibrary",
                  },
                ],
              },
            ],
          },
        },
      ],
      navs: [
        {
          name: "全程健康管理平台",
          path: ["WholeProcessHealth"],
          children: {
            tab: [
              // {
              //   name:'',
              //   list:[
              //     {
              //       name:'全程健康生态社区',
              //       path:'WholeProcessHealth',
              //     },
              //     {
              //       name:'全方位慢病管理体系',
              //       path:''
              //     }
              //   ]
              // }
            ],
          },
        },

        // {
        //   name: "智慧地带",
        //   path: "",
        //   children: {
        //     tab: [
        //       {
        //         name: "",
        //         list: [
        //           {
        //             name: "案例名称",
        //             path: "",
        //           },
        //           {
        //             name: "合作伙伴",
        //             path: "",
        //           },
        //         ],
        //       },
        //     ],
        //   },
        // },
        {
          name: "生态招募",
          path: ["EcologicalRecruitment"],
          children: {
            tab: [
              // {
              //   name:'',
              //   list:[
              //     {
              //       name:'招募方向',
              //       path:'EcologicalRecruitment',
              //     },
              //     {
              //       name:'合作共赢',
              //       path:''
              //     }
              //   ]
              // }
            ],
          },
        },
        {
          name: "关于我们",
          path: "",
          children: {
            tab: [
              {
                name: "",
                list: [
                  {
                    name: "公司介绍",
                    path: "AboutUs",
                  },
                  {
                    name: "招贤纳士",
                    path: "Recruit",
                  },
                ],
              },
            ],
          },
        },
        {
          name:"新闻中心",
          path:["NewsCenter"],
          children:{
            tab:[]
          }
        }
      ],
    };
  },
  methods: {
    jump_nav_menu(item) {
      if (item.children.tab.length == 0) {
        this.$router.push({
          path: "/" + item.path[0],
        });
      }
    },
    jump_nav(item) {
      if (!item.path) {
        return;
      }
      this.$router.push({
        path: "/" + item.path,
      });
    },
  },
};
</script>

<style scoped>
.active_nav {
  color: #1aa858;
}
.active_choose {
  color: #1aa858 !important;
}
.box {
  background: #212122;
  color: #fff;
  padding: 40px 40px;
}
.box h2 {
  line-height: 200%;
  font-size: 16px;
  white-space: nowrap;
}
.box li {
  cursor: pointer;
  font-size: 12px;
  /* padding: 6px 0; */
  list-style: none;
  color: #999;
}
.navTab {
  padding: 6px 0;
}
.box .active_type {
  color: #fff;
  padding: 6px 0;
}
.left_box > div {
  margin-right: 30px;
}
.is_code {
  width: 90px;
  height: 90px;
  margin: 10px 0 40px 10px;
  background: #fff;
}
.navBoxItem {
  margin-bottom: 16px;
}
li:hover {
  color: #1aa858;
}
.mainTabJump:hover {
  cursor: pointer;
  color: #1aa858;
}
.desc {
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .box {
    padding: 40px 80px;
  }
  .left_box > div {
    margin-right: 50px;
  }
  .left_box {
    margin-right: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .left_box {
    display: none;
  }
  .right_box {
    text-align: center !important;
  }
  .box {
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .desc {
    font-size: 14px;
    line-height: 200%;
    margin: 0 30px !important;
    margin-top: 20px !important;
  }
  .is_code {
    width: 120px;
    height: 120px;
    margin: 10px 10px;
  }
}
</style>
